import React, { useEffect, useRef, useState } from 'react';
import Otp from '../components/otp/otp';
import { Link, useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import { verifyMobileOtp } from './services/authentication';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const fullName = sessionStorage.formFields ? JSON.parse(sessionStorage.formFields).full_name : null;
export default function PhoneValidation({
    phone,
    formFields,
    setFormFields,
    otpState,
    setOtpState,
    sendOtpAction,
    setResendAction,
    showResendMess,
    handleSubmit,
    setShowResendMess,
}) {
    const history = useHistory();
    const [prefilledOtp, setPrefilledOtp] = useState(false);
    const [otp, setOtp] = useState(prefilledOtp || '');
    const [isVerified, setIsVerified] = useState(false);
    const [showResend, setShowResend] = useState(false);
    const [timeLeft, setTimeLeft] = useState(30);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const submitBtnRef = useRef(null);

    useEffect(() => {
        const otp = sessionStorage.otp;
        if (otp) {
            setPrefilledOtp(true);
            setOtp(otp);
        }
    }, []);
    // show resend button after 30 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowResend(true);
        }, 30000);
        return () => clearTimeout(timer);
    }, [otpState]);

    useEffect(() => {
        submitBtnRef.current.disabled = isSubmitting;
    }, [isSubmitting]);

    // countdown timer
    useEffect(() => {
        const timer = setInterval(() => {
            if (timeLeft > 1) {
                setTimeLeft(timeLeft - 1);
            } else setShowResend(true);
        }, 1000);
        return () => clearInterval(timer);
    }, [timeLeft]);

    const verifyMobileOtpAction = async () => {
        setIsSubmitting(true);
        const institution = sessionStorage.institution;
        if (institution) {
            const tournament = sessionStorage.redirectToURL.split('/')[2].split('?')[0];
            return await verifyMobileOtpMutation.mutateAsync({ phone, otp, institution, tournament });
        } else return await verifyMobileOtpMutation.mutateAsync({ phone, otp });
    };

    const verifyMobileOtpMutation = useMutation((data) => verifyMobileOtp(data), {
        onSuccess: (res) => {
            if (res.message === 'success') {
                setFormFields({ ...formFields });
                setIsVerified(true);
            } else {
                setIsSubmitting(false);
                setOtpState('invalidOTP');
            }
        },
        onError: async (err) => {
            setIsSubmitting(false);
            console.error('error -', err);
        },
    });

    useEffect(() => {
        if (isVerified) {
            // history.push('/signup/personal-details');
            handleSubmit();
        }
    }, [isVerified]);

    // useEffect(() => {
    //   if (formFields.role === '') {
    //     history.push('/signup/');
    //   }
    // }, []);
    return (
        <>
            {!prefilledOtp && (
                <>
                    <div className="pt-3 text-gray-700 font-roboto info">
                        Please enter the OTP sent to you on your Mobile Phone for verification.
                    </div>
                    <div className={'pt-2 pb-5 cursor-pointer'}>
                        {phone} :{' '}
                        <a
                            className={'cursor-pointer'}
                            onClick={() => {
                                setFormFields({ ...formFields, iAgree: false, submit: false });
                                setOtpState('');
                                history.push('/signup/edit-phone');
                            }}
                        >
                            Edit
                        </a>
                    </div>
                </>
            )}
            <Otp code={otp} setCode={setOtp} prefilledOtp={prefilledOtp} />
            {!prefilledOtp ? (
                <div>
                    {!showResend ? (
                        <div className="mt-5 flex justify-between mb-5 w-full">
                            <div className="text-gray-700 font-roboto info">
                                Resend OTP in <span className="text-red-500 font-bold">{timeLeft}</span> seconds
                            </div>
                        </div>
                    ) : (
                        <div className="mt-5 flex justify-between mb-5 w-full">
                            <div className="text-gray-700 font-roboto info">
                                <a
                                    onClick={() => {
                                        setResendAction(true);
                                        setShowResend(false);
                                        setShowResendMess(true);
                                        setTimeLeft(30);
                                        sendOtpAction({ ...formFields, ...{ name: fullName } });
                                    }}
                                    className="cursor-pointer"
                                >
                                    Resend Code
                                </a>
                            </div>
                        </div>
                    )}
                    <div className={`${otpState === 'success' ? 'text-green-500' : 'text-red-500'} my-3`}>
                        {otpState === 'success' ? (
                            showResendMess ? (
                                'Please enter the OTP sent to you on your Mobile Phone for verification.'
                            ) : (
                                'Kindly Enter The OTP Received On Your Mobile'
                            )
                        ) : otpState === 'exists' ? (
                            <span>
                                User Already Exists! <Link to="/signup/">go Back</Link>
                            </span>
                        ) : otpState === 'error' ? (
                            <span>
                                Error Occured! <Link to="/signup/personal-details">go Back</Link>
                            </span>
                        ) : otpState === 'invalidOTP' ? (
                            'Invalid OTP'
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            ) : (
                <div className="mb-6"></div>
            )}
            <div className="flex justify-between w-full">
                <button
                    className="reg-btn-dark w-full my-2 rounded-lg px-10 py-2 text-white"
                    ref={submitBtnRef}
                    type={'button'}
                    onClick={() => {
                        verifyMobileOtpAction();
                    }}
                >
                    Submit{isSubmitting && <FontAwesomeIcon icon={faSpinner} spin={true} />}
                </button>
            </div>
        </>
    );
}
