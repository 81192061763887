// import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { ErrorMessage, Field, Form, Formik } from 'formik';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import * as Yup from 'yup';
import { useQuery } from 'react-query';
import { checkValidPlatformInvite } from 'authentication/services/authentication';
import Loader from 'components/Loader';
import { useHistory } from 'react-router-dom';
export default function SignUpFormik({
    formFields,
    isAthleteOnly,
    setFormValues,
    handleSubmitAndVerify,
    tournamentId,
}) {
    const [error, setError] = useState(null);
    const history = useHistory();
    const valid = useQuery(
        ['checkplatformValid', sessionStorage?.institution, tournamentId, sessionStorage?.otp],
        () =>
            checkValidPlatformInvite({
                institutionId: sessionStorage?.institution,
                tournamentId,
                otp: sessionStorage?.otp,
            }),
        {
            retry: false,
            enabled: !!sessionStorage?.institution && !!sessionStorage?.otp,
        }
    );

    const validationSchema = Yup.object({
        phone: Yup.string()
            .required('Kindly Enter Your Phone Number')
            .matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits'),
    });

    if (valid.isFetching) return <Loader />;
    if (valid.isFetched && valid?.data?.message === false) {
        return (
            <>
                <div className="text-red-900 bg-red-100 border-red-200 px-4 py-2">
                    The Platform Invite Link You Have Used Does Not Exist. Please Click The Button Below To Sign Up
                </div>
                <button
                    className="btn btn-primary btn-md btn-block mt-8 bg-gray-750 hover:bg-blue-800"
                    onClick={() => {
                        sessionStorage.clear();
                        history.push('/signup');
                    }}
                >
                    Sign Up
                </button>
            </>
        );
    }

    return (
        <>
            {error && (
                <div className="text-red-900 bg-red-100 border-red-200 px-4 py-2">
                    Please fix the following errors before submitting.
                    <br />
                    <span className="underline">{error?.error || error.message || error}</span>
                </div>
            )}
            <Formik
                initialValues={{
                    athlete_full_name: '',
                    coach_full_name: '',
                    phone: '',
                    email: '',
                    institution_full_name: '',
                    iAgree: false,
                    ...formFields,
                    role: isAthleteOnly ? 'athlete' : formFields.role || '',
                }}
                validationSchema={validationSchema}
                validateOnChange
                validateOnBlur
                onSubmit={async (values) => {
                    // Create email from phone number
                    const emailFromPhone = `${values.phone}@sfaplay.com`;

                    // Update values with the new email format and athlete_full_name
                    const updatedValues = {
                        ...values,
                        email: emailFromPhone,
                        athlete_full_name: `${values.phone} sfaUser`,  // Concatenate phone and " sfaUser"
                    };

                    handleSubmitAndVerify({ data: updatedValues }, setError);
                }}
            >
                {({ isSubmitting, values, setValues, isValid }) => {
                    return (
                        <Form id="signi=In-form">

                            <label className="font-semibold text-sm text-gray-650 mt-6 pb-2 md:pb-1 block field-required">
                                Phone Number
                            </label>
                            <div className="flex w-full bg-white border-2 rounded-lg">
                                <Field
                                    type="text"
                                    name="phone"
                                    placeholder="Enter phone number"
                                    className="w-full p-2 border-0 rounded"
                                />
                            </div>
                            <ErrorMessage name="phone" component="div" className="text-red-600 text-sm mt-2" />

                            <ErrorMessage name="iAgree" component="div" className="text-red-600 text-sm mt-2" />

                            <button
                                className="btn btn-primary btn-md btn-block mt-8 bg-gray-750 hover:bg-blue-800"
                                type="submit"
                                disabled={!values.phone?.length || !isValid || isSubmitting}
                            >
                                Submit
                                {isSubmitting && <i className="fa fa-refresh fa-spin button-icon-right"></i>}
                            </button>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
}
